const IconInstagram = () => (
    <svg viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.4258 2.05029H7.17578C4.34532 2.05029 2.05078 4.34483 2.05078 7.17529V17.4253C2.05078 20.2558 4.34532 22.5503 7.17578 22.5503H17.4258C20.2562 22.5503 22.5508 20.2558 22.5508 17.4253V7.17529C22.5508 4.34483 20.2562 2.05029 17.4258 2.05029Z"
            stroke="currentColor"
            strokeWidth="1.5375"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M16.3998 11.6544C16.5263 12.5074 16.3806 13.3786 15.9834 14.1441C15.5862 14.9096 14.9578 15.5303 14.1875 15.918C13.4172 16.3057 12.5442 16.4407 11.6928 16.3037C10.8414 16.1667 10.0548 15.7647 9.44501 15.1549C8.83521 14.5451 8.43322 13.7586 8.29622 12.9071C8.15921 12.0557 8.29416 11.1828 8.68188 10.4124C9.0696 9.64214 9.69034 9.0137 10.4558 8.61651C11.2213 8.21932 12.0925 8.07362 12.9455 8.20011C13.8157 8.32915 14.6213 8.73461 15.2433 9.35663C15.8653 9.97864 16.2708 10.7842 16.3998 11.6544Z"
            stroke="currentColor"
            strokeWidth="1.5375"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17.9375 6.6626H17.9478"
            stroke="currentColor"
            strokeWidth="1.5375"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default IconInstagram;
